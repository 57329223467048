<template>
    <div>
        <div v-if="authUserPermission['duplicate-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4 mt--7">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-4">
                                <h3 class="mb-0">{{ tt('check_duplicate_by_part_number') }}</h3>
                            </div>
                            <div class="col-8 text-right">
                                <a v-if="authUserPermission['duplicate-by-part-number-export']" :href="apiUrl+'CheckDuplicateByPartNumber/export?token='+token" class="btn btn-sm btn-outline-dark">Export as Excel</a>
                                <!-- <input type="button" class="btn btn-sm btn-outline-dark" value="Export as Excel" @click="exportExcel"> -->
                                <input type="hidden" class="day_month_year" value="">
                                <div class="btn-group" role="group">
                                    <select class="btn btn-outline-dark btn-sm" name="day_month_year" @change="changeFilter">
                                        <option value="2">By Part Number</option>
                                        <option value="1">By Description</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad" :row-class-name="tableRowClassName">
                            <!-- <el-table-column :label="tt('number')" :prop="tt('number')" min-width="150" sortable>
                                <template v-slot="{row}">
                                    {{row.number}}
                                </template>
                            </el-table-column> -->
                            <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.material_number}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('item_name')" :prop="tt('item_name')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.item_name}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('short_description')" :prop="tt('short_description')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.short_description}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('material_type')" :prop="tt('material_type')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.material_type}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('material_group')" :prop="tt('material_group')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.material_group}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('uom')" :prop="tt('uom')" min-width="130" sortable>
                                <template v-slot="{row}">
                                    {{row.uom}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('mfr_code')" :prop="tt('mfr_code')" min-width="150" sortable>
                                <template v-slot="{row}">
                                    {{row.manufacture_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('mfr_ref')" :prop="tt('mfr_ref')" min-width="150" sortable>
                                <template v-slot="{row}">
                                    {{row.manufacture_ref}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('cat_status')" :prop="tt('cat_status')" min-width="150" sortable>
                                <template v-slot="{row}">
                                    {{row.catalog_status}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div> 
            </div>
    <!--     <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="inputCheckDuplicate.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" rules="required">
                    <el-select class="select-danger" v-model="inputCheckDuplicate.table" placeholder="Choose Table">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('column') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('column')" rules="required">
                    <el-select class="select-danger" v-model="inputCheckDuplicate.column" placeholder="Choose Column">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal> -->
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import checkDuplicate from '@/services/report/checkDuplicate.service';
    import baseApiUrl from '@/configs/config';
    
    export default {        
        data() {
            return {    
                sequence: 0,
                onLoad: false,            
                form: {
                    add: true,
                    title: this.tt('add_table_column'),
                    show: false
                }, 
                table: {                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },        
                inputCheckDuplicate: {
                    filter: 1
                }  ,
                dataCheckDuplicate: {},
                token: localStorage.token,
                apiUrl: baseApiUrl.apiUrl,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get(filter=this.inputCheckDuplicate.filter) {
                let context = this;
                let api = null;

                context.onLoad = true;
                api = Api(context, checkDuplicate.checkDuplicateByPartNumber(this.table.page.current_page));
                api.onSuccess(function(response) {
                    context.table.data  = response.data.data.data.data; 
                    context.table.total = response.data.data.data.total
                    context.table.page  = response.data.data.data; 
                    context.onLoad      = false;
                }).onFinish(function() {
                    context.onLoad = false;
                }).call();
            },
            changeFilter() {
                if (this.inputCheckDuplicate.filter == 1) {
                    this.$router.push({ name: 'Check Duplicate', path: '/report/check-duplicate' })
                } else {
                    this.$router.push({ name: 'Check Duplicate By Part Number Report', path: '/report/check-duplicate-by-part-number-report' })
                }
            },
            exportExcel() {
                let context = this;
                Api(context, checkDuplicate.export_duplicate_by_description()).onSuccess(function(response) {
                    console.log(response.data.data)
                }).call();
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_table_column');
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('edit_table_column');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
           tableRowClassName({row, rowIndex}) {
                // var seq = this.highlight(rowIndex);

                if (row.dup == true) {
                    return 'warning-row';
                } else {
                    return '';
                }
            },
            // highlight(seq) {
            //     if (seq > 0) {
            //         if (this.table.data[seq].spn === this.table.data[seq-1].spn) {
            //             return this.sequence
            //         } else {
            //             this.sequence = this.sequence+1
            //             return this.sequence
            //         }
            //     } else {
            //         return 1
            //     }
            // }
        }   
    };
</script>
<style>
  .el-table div .warning-row {
    background: #ffe391 !important;
  }
</style>
